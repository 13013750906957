import { Typography } from '@material-ui/core';
import { useState } from 'react';

export function LoadForAllResponsiblesButton({ onClick, shouldShow }) {
  const [hovered, setHovered] = useState(false);

  return (
    <Typography
      style={{
        display: shouldShow() ? 'block' : 'none',
        marginTop: '10px',
        fontSize: '12px',
        cursor: 'pointer',
        textDecoration: hovered ? 'underline' : 'none',
        color: hovered ? '#000': '#3d3d3d',
      }}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      Load this effort for all responsibles
    </Typography>
  );
}
