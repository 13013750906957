import _, { isEqual, mergeWith } from 'lodash';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { del, get, getErrorMessage, handleResponse, patch, post } from '../../../utils/network';
import { createTask } from '../../../utils/tasks';
import { getUnroundedDuration, getUserId } from '../../../utils/utils';

const mergeCustomizer = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
    return _.intersectionWith(objValue, srcValue, isEqual);
  }
};

const useBigCalendar = ({ setWarningMessage, setErrorMessage, setSuccessMessage, setLoading }) => {
  const [events, setEvents] = useState([]);
  const userId = useSelector(getUserId);

  const deleteEvent = async e => {
    const { project, iteration, story, entryId, task } = e;

    const previousEvents = Object.assign([], events);
    setEvents(events.filter(evt => evt.id !== entryId));

    const query = story?.id
      ? `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks/${task.id}/entries/${entryId}`
      : `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks/${task.id}/entries/${entryId}`;

    try {
      const res = await del(query);
      await handleResponse(res);
    } catch (err) {
      setEvents(previousEvents);
      return Promise.reject(err);
    }
  };

  const createEventAndTask = async ({ project, iteration, story, task, state, responsibles, effort, newEvent }) => {
    const { task: taskId, entry: entryId } = await createTask(
      project,
      iteration,
      story,
      task,
      state,
      responsibles,
      effort
    );
    newEvent.id = entryId;
    newEvent.resource.task.id = taskId;
    setSuccessMessage('Effort added successfully');
    return newEvent;
  };

  const createEvent = async ({ project, iteration, story, id, state, responsibles, effortBody, newEvent }) => {
    if (state || responsibles) {
      await editTask(newEvent, { state, responsibles: responsibles.map(r => r.id) });
    }

    let query;

    if (story?.id)
      query = `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks/${id}/entries`;
    else query = `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks/${id}/entries`;

    setLoading(true);
    const res = await post(query, effortBody);
    const { message: entryId } = await handleResponse(res, {}, [{ status: 400, method: getErrorMessage }]);
    newEvent.id = entryId;
    setSuccessMessage('Effort added successfully');
    return newEvent;
  };

  const addEvent = async e => {
    const { start, end, name, project, iteration, story, id: taskId, comments, state, responsibles, participants } = e;
    const newEvent = {
      start,
      end,
      title: name,
      resource: {
        project,
        iteration,
        story,
        task: {
          id: taskId,
          name,
        },
        comments,
      },
    };
    const participants_id = participants.map(p => p.id);

    const duration = getUnroundedDuration(start, end) * 60;

    const effortBody = {
      date: end,
      minutesSpent: Math.round(duration),
      description: comments ?? '',
      participants: participants_id,
    };

    let event;
    try {
      if (!taskId) {
        event = await createEventAndTask({
          project,
          iteration,
          story,
          task: { name },
          state,
          responsibles,
          effort: effortBody,
          newEvent,
        });
      } else {
        event = await createEvent({
          project,
          iteration,
          story,
          id: taskId,
          state,
          responsibles,
          effortBody,
          newEvent,
        });
      }
      if (participants_id.includes(userId)) {
        setEvents(prev => [...prev, event]);
      }
    } catch (err) {
      setErrorMessage('There was an error adding effort');
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const editEvent = async ({ originalEvent, eventChanges }) => {
    const {
      id: entryId,
      resource: { project, iteration, story, task },
    } = originalEvent;
    const { start, end, resource } = eventChanges;

    if (resource?.state || resource?.responsibles) {
      const { state, responsibles, ...rest } = resource;
      await editTask(originalEvent, { state: resource.state, responsibles: resource.responsibles?.map(r => r.id) });
      if (Object.keys(rest).length === 0) return;
    }

    const query = story?.id
      ? `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks/${task.id}/entries/${entryId}`
      : `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks/${task.id}/entries/${entryId}`;

    const body = {};

    if (start || end) {
      const newStart = start ?? originalEvent.start;
      const newEnd = end ?? originalEvent.end;
      const duration = getUnroundedDuration(newStart, newEnd) * 60;
      body.minutesSpent = duration;
      if (!isEqual(originalEvent.end, end)) body.date = newEnd;
    }
    if (resource?.comments) body.description = resource.comments;

    const previousEvents = Object.assign([], events);
    setEvents(prev =>
      prev.map(event => (event.id === entryId ? mergeWith(event, eventChanges, mergeCustomizer) : event))
    );
    if (Object.keys(body).length === 0) return Promise.resolve();

    setLoading(true);
    try {
      const response = await patch(query, body);
      if (response.status === 204) {
        setSuccessMessage('Effort edited successfully');
        return entryId;
      }
    } catch (err) {
      setErrorMessage('There was an error editing effort');
      setEvents(previousEvents);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const isMovement = (event, start, end) => event.end - event.start === end - start;

  const editEventTime = ({ event, start, end }) => {
    const invalidTime = (end - start) / 3600000 > 8 && !isMovement(event, start, end); //if its an edition of more than 8h its most likely a mistake

    if (invalidTime) {
      setWarningMessage(
        "Remember that it's recommended to load efforts smaller than 8 hours. If you want to edit an effort with bigger duration, please click on the effort to access the manual load."
      );
      return;
    }

    if (end - start <= 900000) {
      setWarningMessage("You can't resize an event to less than 15 minutes");
      return;
    }

    const eventChanges = {};
    if (start !== event.start) eventChanges.start = start;
    if (end !== event.end) eventChanges.end = end;

    editEvent({
      originalEvent: event,
      eventChanges: eventChanges,
    });
  };

  const editTask = async (event, { state, responsibles }) => {
    const {
      id: entryId,
      resource: { project, iteration, story, task },
    } = event;

    const previousEvents = Object.assign([], events);
    setEvents(prev =>
      prev.map(event => (event.id === entryId ? { ...event, resource: { ...event.resource, state } } : event))
    );

    let query;

    if (story?.id)
      query = `clients/0/projects/${project.id}/iterations/${iteration.id}/stories/${story.id}/tasks/${task.id}`;
    else query = `clients/0/projects/${project.id}/iterations/${iteration.id}/tasks/${task.id}`;

    setLoading(true);
    try {
      const response = await patch(query, { state: state?.id ?? 0, responsibles });
      if (response.status === 204) {
        setSuccessMessage('Task state edited successfully');
        return entryId;
      }
    } catch (err) {
      setErrorMessage('There was an error editing task');
      setEvents(previousEvents);
      return Promise.reject(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchEvents = useCallback(
    async ({ start, end }) => {
      const query = `users/${userId}/entries?start=${start}&end=${end}`;
      setLoading(true);
      try {
        const res = await get(query);
        const { message: entries } = await handleResponse(res);
        const events = entries.map(entry => ({
          start: moment(new Date(entry.date)).subtract(entry.minutesSpent, 'minutes').toDate(),
          end: new Date(entry.date),
          title: entry.task.name,
          id: entry.id,
          resource: {
            project: entry.project,
            iteration: entry.iteration,
            story: entry.story,
            task: entry.task,
            comments: entry.description,
            responsibles: entry.responsibles || [],
          },
        }));
        setEvents(events);
      } catch (err) {
        setErrorMessage('There was an error fetching events');
      } finally {
        setLoading(false);
      }
    },
    [setErrorMessage, setLoading, userId]
  );

  return { events, addEvent, editEventTime, editEvent, fetchEvents, deleteEvent };
};

export default useBigCalendar;
