import {
  Box, Button, Container, Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles, Typography
} from '@material-ui/core';

import { ErrorOutline } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { isEmpty, isEqual } from 'lodash';
import 'moment/locale/es-mx';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { createStory } from '../../../utils/stories';
import { getTasksPath } from '../../../utils/tasks';
import { fetchTaskInfo, getDuration, getUserId, STATES } from '../../../utils/utils';
import ApiAutocomplete from '../Autocomplete/ApiAutocomplete';
import ArrayAutocomplete from '../Autocomplete/ArrayAutocomplete';
import AutocompleteChipSection from '../Autocomplete/AutocompleteChipSection';
import { PrimaryButton } from '../Button';
import useFormValidation from '../hooks/useFormValidation';
import LoadEffortTime from '../LoadEffortTime';
import { TextInput } from '../TextInput';
import { LoadForAllResponsiblesButton } from './LoadForAllResponsiblesButton';
import validate from './validate';

const me = userId => ({
  id: userId,
  fullName: 'You'
});

const INITIAL_STATE = userId => ({
  project: '',
  iteration: '',
  story: '',
  task: '',
  state: '',
  start: new Date(),
  end: new Date(),
  duration: 0,
  responsibles: [ me(userId) ],
  participants: [ me(userId) ],
  comments: '',
});

const LABELS = {
  project: 'Project',
  iteration: 'Iteration',
  story: 'Story',
  task: 'Task',
  state: 'State',
  start: 'Start',
  end: 'End',
  duration: 'Duration',
  responsibles: 'Responsibles',
  comments: 'Comments',
};

const useStyles = makeStyles(() => ({
  IconButton: {
    cursor: 'pointer',
    float: 'right',
    width: '20px',
  },
  deleteButton: { color: '#e53e3e' },
  dialogTitle: { padding: '24px 16px 0px', textAlign: 'center' },
  dialogContent: { display: 'flex', flexDirection: 'column', paddingTop: 0 },
  warningText: { marginBottom: 0, color: '#ff9800', fontSize: 16 },
  endContainer: { display: 'flex' },
  dialogActions: { display: 'flex', justifyContent: 'space-between', padding: '16px 24px 24px' },
}));

const errorMessageMapper = (err) => {
  console.error(err);
  const { location, param } = err[0];
  if(location === 'body' && param.includes('participants')) { 
    return err[0].msg.err_msg + ': ' + err[0].msg.users.map(u => u.fullName).join(', ');
  }
  return err[0].msg;
}

const ManualLoadDialog = ({ open, handleClose, info, addEvent, editEvent, history, deleteEvent }) => {
  const userId = useSelector(getUserId);
  const classes = useStyles();
  const { handleSubmit, handleChange, handleBlur, values, errors, resetValues, resetError, setErrors } =
    useFormValidation(INITIAL_STATE(userId), v => validate(v));

  const [data, setData] = useState({}); //Data before changes
  const isNew = Boolean(info?.isNew);

  const handleChangeAutocomplete = useCallback(
    (name, value) => handleChange({ target: { name, value } }),
    [handleChange]
  );
  const handleChangeProject = useCallback(
    value => handleChangeAutocomplete('project', value),
    [handleChangeAutocomplete]
  );
  const handleChangeIteration = useCallback(
    value => handleChangeAutocomplete('iteration', value),
    [handleChangeAutocomplete]
  );
  const handleChangeStory = useCallback(value => handleChangeAutocomplete('story', value), [handleChangeAutocomplete]);
  const handleChangeTask = useCallback(value => handleChangeAutocomplete('task', value), [handleChangeAutocomplete]);
  const handleChangeState = useCallback(value => handleChangeAutocomplete('state', value), [handleChangeAutocomplete]);

  const [error, setError] = useState('');

  

  const handleDeleteEffort = () => {
    const { project, iteration, story, task } = info.resource;
    return Swal.fire({
      title: 'Do you want to delete this effort?',
      text: "You won't be able to recover this effort!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      showLoaderOnConfirm: true,

      preConfirm: () => deleteEvent({ project, iteration, story, task, entryId: info.id }),
      //Forbides the user to click outside the SWAL while is fetching
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then(result => {
        if (result.isConfirmed) {
          Swal.fire('Deleted!', 'Your effort has been deleted.', 'success');
        }
      })
      .catch(error => {
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };

  const handleCloseDialog = () => {
    setError('');
    resetValues(INITIAL_STATE(userId));
    handleClose();
  }

  const getChangedValues = values => {
    const changedValues = [];
    Object.keys(data).forEach(key => {
      if (!isEqual(data[key], values[key])) changedValues.push(key);
    });
    return changedValues;
  };

  const handleEditEvent = async values => {
    const changedValues = getChangedValues(values);
    if (changedValues.length === 0) {
      handleCloseDialog();
      return;
    }
    let eventChanges = {};
    changedValues.forEach(k => {
      if (k === 'duration') return;
      else if (k === 'start' || k === 'end') eventChanges = { ...eventChanges, [k]: values[k] };
      else
        eventChanges = {
          ...eventChanges,
          resource: { ...eventChanges.resource, [k]: values[k] },
        };
    });
    return await editEvent({ eventChanges, originalEvent: info });
  }

  const handleCreateEvent = async values => {
    const { project, iteration, story, task, state, start, end, comments, responsibles, participants } = values;

      return await addEvent({
        start,
        end,
        name: task.name,
        project,
        iteration,
        story,
        id: task.id,
        comments,
        responsibles,
        state,
        participants,
      });
  }

  const handleCreateStory = async values => {
    const { project, iteration, state, responsibles } = values;
    if(values.story?.id) return values.story;
    if(values.story?.name) return await createStory(project, iteration, state, responsibles, values.story.name)
    return undefined;
  }

  const handleResult = async e => {
    const formIsValid = handleSubmit(e);
    if (!formIsValid) {
      return;
    }

    const story = await handleCreateStory(values);

    const handleFunction = isNew ? handleCreateEvent : handleEditEvent;
    try {
      await handleFunction({ ...values, story });
      handleCloseDialog(true);
    } catch(err) {
      setError(errorMessageMapper(err));
    }
  };

  const updateStateAndResponsibles = useCallback(({state, responsibles})=> {
    const value = responsibles.map(r => ({
      id: r.id,
      fullName: r.id === userId? "You" : r.fullName ?? "Unknown",
    }))
    handleChangeState(state);
    handleChange({ target: { name: 'responsibles', value } });
    setData(prev => ({ ...prev, responsibles: value, state }));
  }, [handleChange, handleChangeState, userId]);

  useEffect(() => {
    if(!isNew) return;
    if(!values.task.id){
      return updateStateAndResponsibles({ responsibles: [{id: userId}]});
    }
    if([values.project, values.iteration, values.story, values.task].some(it => !it)) return;
    fetchTaskInfo(0, values.project.id, values.iteration.id, values.story?.id, values.task.id)
      .then(updateStateAndResponsibles)
      .catch(handleChangeState(STATES[0]));
  }, [
    handleChangeState,
    isNew,
    values.project,
    values.iteration,
    values.story,
    values.task,
    handleChange,
    updateStateAndResponsibles,
    userId,
  ]);

  useEffect(() => {
    if (!info || isNew || !info.resource) return;
    const { project, iteration, story, task } = info.resource;
    fetchTaskInfo(
      0, // Client
      project.id,
      iteration.id,
      story?.id,
      task.id
    ).then(updateStateAndResponsibles);
  }, [handleChange, handleChangeState, info, isNew, updateStateAndResponsibles]);

  useEffect(() => {
    if (info) {
      const newValues = {
        ...INITIAL_STATE(userId),
        ...info.resource,
        start: info.start,
        end: info.end,
        duration: getDuration(info.start, info.end),
      };
      setData(newValues);
      setErrors({});
      resetValues(newValues);
    }
  }, [setErrors, resetValues, info, userId]);

  const customHandleAdd = useCallback(
    (field, value) =>
      ({ id, fullName }) =>
        handleChange({
          target: {
            name: field,
            value: [...value, { id, fullName: id === userId ? 'You' : fullName }],
          },
        }),
    [handleChange, userId]
  );

  const handleDeleteChip = useCallback(
    (field, id) => {
      return handleChange({
        target: {
          name: field,
          value: values[field].filter(v => v.id !== id),
        },
      });
    },
    [handleChange, values]
  );

  const customHandleAddParticipants = useCallback(
    ({ id, fullName }) => {
      const responsiblesId = values.responsibles.map(r => r.id);
      if (!responsiblesId.includes(id)) {
        customHandleAdd('responsibles', values.responsibles)({ id, fullName });
      }
      return customHandleAdd('participants', values.participants)({ id, fullName });
    },
    [customHandleAdd, values.participants, values.responsibles]
  );

  const customHandleDeleteResponsibles = useCallback(
    ({ id, fullName }) => {
      const participantsId = values.participants.map(p => p.id);
      if (participantsId.includes(id) && isNew) {
        handleDeleteChip('participants', id);
      }
      return handleDeleteChip('responsibles', id);
    },
    [handleDeleteChip, isNew, values.participants]
  );

  const shouldShowTextToLoadEffortForAllResponsibles = () => {
    return isNew && !isEmpty(values.responsibles) && !isEqual(values.responsibles, [ me ]);
  }

  const loadEffortForAllResponsibles = () => {
    return handleChange({
      target: {
        name: 'participants',
        value: [...values.responsibles]
      }
    });
  }

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleCloseDialog}>
      <DialogContent className={classes.dialogContent}>
        <Box>
          <IconButton onClick={handleCloseDialog} variant="text" size="small" className={classes.IconButton}>
            <CloseIcon />
          </IconButton>

          <DialogTitle className={classes.dialogTitle}>{isNew ? 'Load effort' : 'Edit effort'}</DialogTitle>
        </Box>

        <ApiAutocomplete
          extraFields={['client.name']}
          path={`projects`}
          field="name"
          value={values.project}
          label={LABELS.project}
          handleChange={handleChangeProject}
          error={errors.project}
          disabled={!isNew}
        />
        <ApiAutocomplete
          path={`clients/0/projects/${values.project.id}/iterations`}
          field="name"
          value={values.iteration}
          label={LABELS.iteration}
          handleChange={handleChangeIteration}
          error={errors.iteration}
          disabled={!isNew || !values.project}
          dependsOn={values.project}
        />
        <ApiAutocomplete /* Storie's autocomplete */
          path={`clients/0/projects/${values.project.id}/iterations/${values.iteration.id}/stories`}
          field="name"
          value={values.story}
          label={LABELS.story + ' (Leave empty for a task without story)'}
          handleChange={handleChangeStory}
          error={errors.story}
          disabled={!isNew || !values.iteration}
          dependsOn={values.iteration}
          createText="Create story"
          freeSolo
        />

        <ApiAutocomplete /* Task's autocomplete */
          path={getTasksPath(values)}
          field="name"
          value={values.task}
          label={LABELS.task}
          handleChange={handleChangeTask}
          error={errors.task}
          disabled={!isNew || !values.iteration}
          freeSolo
          dependsOn={values.iteration}
          createText="Create the task"
        />
        <ArrayAutocomplete
          options={STATES}
          error={errors.state}
          label={LABELS.state}
          value={values.state}
          handleChange={handleChangeState}
          field="name"
          useFieldInTextField
          dependsOn={values.task}
          disabled={!values.task}
        />
        <AutocompleteChipSection
          editMode
          path={'users'}
          apiField={'fullName'}
          idField={'id'}
          field="responsibles"
          value={values.task ? values.responsibles ?? [] : []}
          handleChange={handleChange}
          handleBlur={handleBlur}
          helpText={'Add more people to this task so it will appear in their Quick Load Dialog'}
          label={'Add more responsibles to this task'}
          dependsOn={values.task}
          disabled={!values.task}
          customHandleAdd={customHandleAdd('responsibles', values.responsibles)}
          customHandleDelete={customHandleDeleteResponsibles}
          error={errors.responsibles}
        />
        <LoadForAllResponsiblesButton
          onClick={loadEffortForAllResponsibles}
          shouldShow={shouldShowTextToLoadEffortForAllResponsibles}
        />
        <Typography
          style={{
            textAlign: 'center',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          Effort Info
        </Typography>
        <LoadEffortTime
          values={values}
          errors={errors}
          handleChange={handleChange}
          resetError={resetError}
          handleBlur={handleBlur}
          info={info}
        >
          {isNew && (
            <AutocompleteChipSection
              editMode={isNew}
              path={'users'}
              apiField={'fullName'}
              field="participants"
              value={values.task ? values.participants ?? [] : []}
              handleChange={handleChange}
              handleBlur={handleBlur}
              helpText={'Load effort for another people by typing their name'}
              label={'Add more people to this effort'}
              customHandleAdd={customHandleAddParticipants}
              disabled={!values.task}
              error={errors.participants}
              noItemsText={'No people found'}
            />
          )}
        </LoadEffortTime>

        <TextInput
          style={{
            marginTop: isNew ? '16px' : '0',
          }}
          multiline
          maxRows={3}
          name="comments"
          value={values.comments}
          error={errors.comments}
          handleChange={handleChange}
          handleBlur={handleBlur}
          label="Description"
        />

        <Container
          style={{
            display: error? 'flex' : 'none',
            justifyContent: 'space-between',
            marginTop: '16px',
            backgroundColor: '#ffcec6',
            borderRadius: '4px',
            padding: '8px',
            alignItems: 'center',
          }}
        >
          <ErrorOutline
            style={{
              color: '#000',
            }}
          />
          <Typography
            style={{
              color: '#000',
              width: '100%',
              textAlign: 'center',
              fontSize: '12px',
            }}
          >
              {error}
          </Typography>

        </Container>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {isNew ? (
          <div></div>
        ) : (
          <Button
            variant="text"
            classes={{
              text: classes.deleteButton,
            }}
            onClick={() => {
              handleClose();
              handleDeleteEffort();
            }}
          >
            Delete Effort
          </Button>
        )}
        <PrimaryButton onClick={handleResult}>Save</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ManualLoadDialog;
