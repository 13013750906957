import * as actions from '../actionTypes';
import generateReducer from './generateReducer';

const initialState = {};

const handler = {
  [actions.LOGIN]: (state, action) => ({
    userId: action.user.userId,
    name: action.user.name,
    initials: action.user.initials,
  }),
  [actions.LOGOUT]: (state, action) => ({}),
};

export default generateReducer(handler, initialState);