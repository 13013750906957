import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  colors,
  DialogContentText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getDuration, getUserId } from '../../utils/utils';
import DateTimePicker from './DateTimePicker';
import { TextInput } from './TextInput';

const LoadEffortTime = ({ values, errors, handleChange, handleBlur, resetError, info, children }) => {
  const userId = useSelector(getUserId);
  const handleChangeStart = useCallback(
    date => {
      handleChange({ target: { name: 'start', value: date } });
      resetError('end');
    },
    [handleChange, resetError]
  );
  const handleChangeEnd = useCallback(
    date => {
      handleChange({ target: { name: 'end', value: date } });
      resetError('start');
    },
    [handleChange, resetError]
  );

  const handleChangeDuration = e => {
    const h = e.target.value;
    handleChangeEnd(moment(values.start).add(h, 'h').toDate());
  };

  useEffect(() => {
    handleChangeStart(info?.start);
    handleChangeEnd(info?.end);
  }, [handleChangeStart, handleChangeEnd, info]);

  const warningText =
    (values?.end - values?.start) / 3600000 > 8 &&
    "Remember that it's recommended to load efforts smaller than 8 hours.";

  const calendarOptions = {
    lastDay : '[yesterday at] HH:mm',
    sameDay : '[today at] HH:mm',
    nextDay : '[tomorrow at] HH:mm',
    lastWeek : '[last] dddd [at] HH:mm',
    nextWeek : 'dddd [at] HH:mm',
    sameElse : 'L'
  }

  const formatTime = (start, end) => {
    const startDate = moment(start);
    const endDate = moment(end);
    if (startDate.isSame(endDate, 'day')) {
      return `${startDate.locale('en').calendar(null, calendarOptions)} to ${endDate.format('HH:mm')}`;
    }
    return `${startDate.locale('en').calendar(null, calendarOptions)} to ${endDate.locale('en').calendar(null, calendarOptions)}`;
  };

  const getParticipants = () => {
    if (isEmpty(values.participants)) return '';
    const length = values.participants.length;
    if (length === 1 && values.participants[0].id === userId) return '';
    const participantsStr = values.participants.map(p => p.fullName).join(', ');
    const indexLastComma = participantsStr.lastIndexOf(',');
    const p =
      length > 1
        ? ', for ' +
          participantsStr.substring(0, indexLastComma) +
          ' and ' +
          participantsStr.substring(indexLastComma + 1)
        : ' for ' + participantsStr;
    return p.replace('You', 'you');
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Tooltip title={warningText}>
          <Warning
            style={{
              color: colors.yellow[800],
              display: warningText ? 'block' : 'none',
              margin: 8,
            }}
          />
        </Tooltip>
        <Typography style={{ fontSize: 14, alignSelf: 'center' }}>
          You are going to load {getDuration(values?.start, values?.end)} hour/s from{' '}
          {formatTime(values?.start, values?.end)}
          {getParticipants()}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <DateTimePicker label="Start" value={values.start} onChange={handleChangeStart} error={errors.end} />
          <div style={{ display: 'flex' }}>
            <DateTimePicker label="End" value={values.end} onChange={handleChangeEnd} error={errors.end} />
            <TextInput
              name="end"
              value={getDuration(values.start, values.end) || 0.3}
              error={errors.end}
              style={{ width: '100%', marginLeft: 30 }}
              handleBlur={handleBlur}
              handleChange={handleChangeDuration}
              label="Duration in hours"
              type="number"
            />
          </div>
          {warningText && (
            <DialogContentText style={{ marginTop: 4, marginBottom: 0, color: '#ff9800', fontSize: 16 }}>
              {warningText}
            </DialogContentText>
          )}
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default LoadEffortTime;
