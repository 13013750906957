import { Button, DialogActions } from '@material-ui/core';
import { default as React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { endTimer, linkWithTask } from '../../../../redux/actions';
import { getUserId } from '../../../../utils/utils';
import Dialog from '../../Dialog';
import TaskList from '../../TaskList/TaskList';

const LinkWithTaskDialog = ({ open, handleClose, manualLoad, selectedTimer, addEvent, loading }) => {
  const userId = useSelector(getUserId);
  const [taskFilter, setTaskFilter] = useState('');
  const timers = useSelector(state => state.timers.timers);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (!selectedTimer) return;
    setTimer(timers.find(t => t.id === selectedTimer.id));
  }, [selectedTimer, timers, setTimer]);

  useEffect(() => {
    if (open) setTaskFilter('');
  }, [setTaskFilter, open]);

  const handleTaskFilterChange = ({ target: { value } }) => {
    setTaskFilter(value);
  };

  const dispatch = useDispatch();
  const handleClickTask = task => {
    dispatch(
      linkWithTask({
        timerId: timer.id,
        task: task,
      })
    );

    if (timer.end) {
      addEvent({
        start: timer.start.toDate(),
        end: timer.end.toDate(),
        name: task.name,
        project: task.project,
        iteration: task.iteration,
        story: task.story,
        id: task.id,
        participants: [{id: userId}]
      });
      dispatch(endTimer(timer.id));
    }
    handleClose(!!timer.end);
  };

  return (
    <Dialog open={open} handleClose={handleClose} title={'Select the task'}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '70vh',
          minHeight: '70vh',
        }}
      >
        <TaskList
          handleClickTask={handleClickTask}
          values={{ taskFilter }}
          handleChange={handleTaskFilterChange}
          loading={loading}
        />
      </div>

      <DialogActions>
        <Button color="primary" onClick={() => manualLoad()}>
          {"Can't find the task"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LinkWithTaskDialog;
