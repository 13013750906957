import moment from 'moment';
import { getDuration } from '../../../utils/utils';

const ERRORS = {
  startBeforeEnd: 'The Start date must be before the End date',
  maxHours: "Efforts can't exceed 15 hours",
  invalidDate: 'Complete with a valid date',
  minimumEffort: 'Efforts must be at least 15 minutes',
};

export const validate = values => {
  let errors = {};
  if (!values.start || !moment(values.start).isValid()) errors.start = ERRORS.invalidDate;

  if (!values.end || !moment(values.end).isValid()) errors.end = ERRORS.invalidDate;

  if (values.start && values.end && values.start >= values.end) {
    errors.start = errors.end = ERRORS.startBeforeEnd;
  }
  if (getDuration(values.start, values.end) > 15) {
    errors.start = errors.end = ERRORS.maxHours;
  }

  if (values.end - values.start < 900000) {
    errors.start = errors.end = ERRORS.minimumEffort;
  }
  return errors;
};
